define("discourse/plugins/discourse-new-features-feeds/discourse/templates/admin-plugins-discourse-new-features-feeds", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h2>
    {{i18n "new_feature_feeds.header"}}
  </h2>
  
  {{d-button
    icon="plus"
    class="discourse-new-features__create-btn"
    label="new_feature_feeds.create"
    action=(action "toggleCreateForm")
  }}
  
  {{#if this.showCreateForm}}
    <DiscourseNewFeaturesForm
      @form={{this.form}}
      @afterSave={{action this.afterSave}}
      @currentVersion={{this.model.meta.current_version}}
      @availableTiers={{this.availableTiers}}
    />
  {{/if}}
  
  {{#if this.model.new_features}}
    <section class="discourse-new-features-table">
      <h3>
        {{i18n "new_feature_feeds.table_header"}}
      </h3>
  
      {{table-new-features
        groupedNewFeatures=this.groupedNewFeatures
        refresh=(action "refresh")
        editItem=(action "editItem")
        copyItem=(action "copyItem")
      }}
    </section>
  {{else}}
    <p>
      {{i18n "new_feature_feeds.no_items"}}
    </p>
  {{/if}}
  */
  {
    "id": "9SO97nNY",
    "block": "[[[10,\"h2\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"new_feature_feeds.header\"],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[1,[28,[35,2],null,[[\"icon\",\"class\",\"label\",\"action\"],[\"plus\",\"discourse-new-features__create-btn\",\"new_feature_feeds.create\",[28,[37,3],[[30,0],\"toggleCreateForm\"],null]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"showCreateForm\"]],[[[1,\"  \"],[8,[39,5],null,[[\"@form\",\"@afterSave\",\"@currentVersion\",\"@availableTiers\"],[[30,0,[\"form\"]],[28,[37,3],[[30,0],[30,0,[\"afterSave\"]]],null],[30,0,[\"model\",\"meta\",\"current_version\"]],[30,0,[\"availableTiers\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"new_features\"]],[[[1,\"  \"],[10,\"section\"],[14,0,\"discourse-new-features-table\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"new_feature_feeds.table_header\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[1,[28,[35,8],null,[[\"groupedNewFeatures\",\"refresh\",\"editItem\",\"copyItem\"],[[30,0,[\"groupedNewFeatures\"]],[28,[37,3],[[30,0],\"refresh\"],null],[28,[37,3],[[30,0],\"editItem\"],null],[28,[37,3],[[30,0],\"copyItem\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,2],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"new_feature_feeds.no_items\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"h2\",\"i18n\",\"d-button\",\"action\",\"if\",\"discourse-new-features-form\",\"section\",\"h3\",\"table-new-features\",\"p\"]]",
    "moduleName": "discourse/plugins/discourse-new-features-feeds/discourse/templates/admin-plugins-discourse-new-features-feeds.hbs",
    "isStrictMode": false
  });
});